import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
import { TOC, TOCList, TOCLink } from "../components/TOC";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Window Size" description="Measure the current window dimensions in React" mdxType="SEO" />
    <h1 {...{
      "id": "window-size"
    }}>{`Window Size`}</h1>
    <TOC mdxType="TOC">
  <TOCList mdxType="TOCList">
    <TOCLink href="#usewindowsize" mdxType="TOCLink">useWindowSize</TOCLink>
    <TOCLink href="#windowsize" mdxType="TOCLink">WindowSize</TOCLink>
  </TOCList>
    </TOC>
    <p>{`Measure the current window dimensions.`}</p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/window-size`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/window-size`}</inlineCode>{`. Then import the component or hook that you need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/window-size
# or
yarn add @reach/window-size
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import WindowSize, { useWindowSize } from "@reach/window-size";
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "windowsize"
    }}>{`WindowSize`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <WindowSize>
      {(size) => (
        <div>
          <p>Resize your window.</p>
          <pre>{JSON.stringify(size, null, 2)}</pre>
        </div>
      )}
    </WindowSize>
  );
}
`}</code></pre>
    <h4 {...{
      "id": "windowsize-props"
    }}>{`WindowSize Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "windowsize-children"
    }}>{`WindowSize children`}</h5>
    <p><inlineCode parentName="p">{`children: (size: { width: number; height: number }) => React.ReactElement<any, any>`}</inlineCode></p>
    <p>{`A function that calls back to you with the window size.`}</p>
    <h3 {...{
      "id": "usewindowsize"
    }}>{`useWindowSize`}</h3>
    <p>{`A hook that observes and returns the measurements of the browser window.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  const { width, height } = useWindowSize();
  return (
    <div>
      <p>Resize your window.</p>
      <pre>Window size: {JSON.stringify({ width, height }, null, 2)}</pre>
    </div>
  );
}
`}</code></pre>
    <h4 {...{
      "id": "usewindowsize-signature"
    }}>{`useWindowSize signature`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`function useWindowSize(): {
  width: number;
  height: number;
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      